import { createContext, useCallback, useState } from 'react';
import { User } from '@hxm/joakim/iron.d';
import useSWR from 'swr';

type SessionContext = {
  user: User | undefined;
  children?: React.ReactNode;
};

export const SessionContext = createContext<SessionContext>({
  user: undefined,
});

export const SessionProvider = ({ children, user }: SessionContext) => {
  const { data } = useSWR<User>('/api/auth/user');
  const [_user, setUser] = useState<User | undefined>(user ?? data);
  useCallback(() => {
    setUser(data);
  }, [data]);
  // if user is null fetch user from /api/auth/user ?2
  return (
    <SessionContext.Provider value={{ user: _user }}>{children}</SessionContext.Provider>
  );
};
