// container Nav component, any data for nav structure should go here
// container gets imported into 'components/layout/Layout'

import { Idgjold } from '@hxm/assets/svg/menu/Idgjold';
import { Lan } from '@hxm/assets/svg/menu/Lan';
import { Lifeyrisgreidslur } from '@hxm/assets/svg/menu/Lifeyrisgreidslur';
import { Lifeyrisrettindi } from '@hxm/assets/svg/menu/Lifeyrisrettindi';
import { Sereign } from '@hxm/assets/svg/menu/Sereign';
import { Settings } from '@hxm/assets/svg/menu/Settings';
import { Skjol } from '@hxm/assets/svg/menu/Skjol';
import { Umsokn } from '@hxm/assets/svg/menu/Umsokn';
import { Yfirlit } from '@hxm/assets/svg/menu/Yfirlit';
import { Button } from '@hxm/components/button/Button';
import { Nav } from '@hxm/components/nav/Nav';
import { NavLink } from '@hxm/components/nav/NavLink';
import { useUiState } from '@hxm/contexts/useUiState';
import { useUser } from '@hxm/hooks/data/useUser';
import { useSettings } from '@hxm/hooks/useSettings';
import { NavItem } from '@hxm/types/navigation';
import { useRouter } from 'next/router';

const icons = {
  Idgjold: Idgjold,
  Lan: Lan,
  Lifeyrisgreidslur: Lifeyrisgreidslur,
  Lifeyrisrettindi: Lifeyrisrettindi,
  Sereign: Sereign,
  Settings: Settings,
  Skjol: Skjol,
  Yfirlit: Yfirlit,
  Umsokn: Umsokn,
};

export type NavContainerProps = {
  navigation: Array<NavItem>;
  publicNav?: boolean;
};

export const NavContainer = ({ navigation, publicNav }: NavContainerProps) => {
  const router = useRouter();
  const { setUIState, uiState } = useUiState();
  const { settings } = useSettings();
  const { user } = useUser(publicNav);
  const editorIds = settings?.editorNationalId ?? [];

  // we'll show the "format keys" button if the user is and admin and the `onlyWebmastersSeeKeys` setting is false
  // or if the current user national id is in the `editorIds` setting field
  const showFormatKeysButton = Boolean(user && editorIds.includes(user.ssn));

  return (
    <Nav publicNav={publicNav}>
      {navigation.map((itm, i) => {
        const Icon =
          itm.icon && itm.icon in icons ? icons[itm.icon as keyof typeof icons] : null;

        return (
          <NavLink
            key={i}
            to={itm.link}
            current={router.pathname.includes(itm.link)}
            childPages={itm.childPages}
          >
            {Icon && <Icon />} {itm.title}
          </NavLink>
        );
      })}
      {showFormatKeysButton && (
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            setUIState({ showFormatKeys: !uiState.showFormatKeys });
          }}
        >
          {uiState.showFormatKeys ? 'Fela' : 'Sýna'} textalykla
        </Button>
      )}
    </Nav>
  );
};
