'use client';

import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

export type LocaleProviderProps = {
  locale: string;
  messages?: Record<string, string>;
  updateActiveLocale: (locale: string) => void;
  children?: React.ReactNode;
};

export const LocaleContext = createContext<LocaleProviderProps>({
  locale: 'is',
  updateActiveLocale: () => null,
});

export const useLocaleContext = () => {
  return useContext(LocaleContext);
};

export const LocaleProvider = ({
  locale = 'is',
  messages,
  children,
}: LocaleProviderProps) => {
  const [activeLocale, setActiveLocale] = useState(locale);
  const [messagesDict, setMessagesDict] = useState<Record<string, string>>(
    messages ?? {}
  );

  useEffect(() => {
    if (locale !== activeLocale) {
      // setActiveLocale(locale); // idk why this is here
      setMessagesDict({});
    }
  }, [locale, activeLocale]);
  // useCallback()?? 👆

  const updateActiveLocale = useCallback((locale: string) => {
    setActiveLocale(locale);
  }, []);

  useEffect(() => {
    setMessagesDict((old) => Object.assign({}, old, messages));
  }, [messages]);

  // check if messages need to be fetched
  return (
    <LocaleContext.Provider
      value={{ locale: activeLocale, messages: messagesDict, updateActiveLocale }}
    >
      <IntlProvider
        messages={messagesDict}
        locale={activeLocale}
        defaultLocale="is"
        onError={() => {
          return undefined;
        }}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
