import { Link, LinkProps } from '../link/Link';

type NavLinkProps = LinkProps & {
  current?: boolean;
  childPages?: Array<{ title: string; link: string }>;
};

export const NavLink = ({ children, current, ...rest }: NavLinkProps) => {
  return (
    <Link {...rest} aria-current={current ? 'page' : undefined} tabIndex={0}>
      {children}
    </Link>
  );
};
