import React from 'react';
import { LocaleSelect } from '@hxm/components/locale-select/LocaleSelect';
import { LoggedinUser } from '@hxm/components/loggedin-user/LoggedinUser';
import { PartialUser, useUser } from '@hxm/hooks/data/useUser';
import { clientFetch, swrFetcher } from '@hxm/utils/clientFetch';
import { ExcludesFalse } from '@hxm/utils/excludesFalse';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { SelectableUser, UserSelect } from 'components/loggedin-user/UserSelect';

const mergeUsers = (
  users: PartialUser['userHasAccessTo'],
  original: PartialUser['originalUser']
): Array<SelectableUser> => {
  const mapped = users.map((user) => ({ ssn: user.kennitala, name: user.nafn }));
  const merged = original
    ? [...mapped, { ssn: original.ssn, name: original.name }]
    : mapped;
  return merged;
};

export const LoggedinUserContainer = () => {
  const { data, error } = useSWR<Array<Locale>>('/api/locales/', swrFetcher);
  const { user, isLoading } = useUser();
  const selectableUsers = mergeUsers(
    user?.userHasAccessTo ?? [],
    user?.originalUser
  ).filter((u) => u.ssn !== user?.ssn);
  const router = useRouter();

  const onUserSelect = (ssn: string) => {
    return clientFetch<PartialUser>(`/api/joakim/settings/impersonate/${ssn}`);
  };

  const NameOrSelect = () =>
    selectableUsers.length > 0 ? (
      <UserSelect
        name={user?.name ?? ''}
        users={selectableUsers}
        onUserSelect={onUserSelect}
      />
    ) : (
      <>{user?.name}</>
    );

  const locales =
    !data && !error
      ? undefined
      : data?.map((locale) => locale.code).filter(Boolean as unknown as ExcludesFalse);

  return !isLoading ? (
    <LoggedinUser
      locales={
        locales && <LocaleSelect locales={locales} selectedLocale={router.locale} />
      }
    >
      <NameOrSelect />
    </LoggedinUser>
  ) : (
    <>Hleð</>
  );
};
