'use client';

import { useCallback, useContext } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { UIContext } from '@hxm/contexts/ui';

const defaultMessage = process.env.NODE_ENV === 'development' ? 'DEV:MISSING' : '';

export type RequiredMessages<R extends string> = { [K in R]: MessageDescriptor };

// First type parameter are the required keys, second parameter are the optional keys
export type IncludesMessages<
  R extends string,
  O extends string = never
> = RequiredMessages<R> & Partial<RequiredMessages<O>>;

export const useMessages = () => {
  const { formatMessage: format, locale } = useIntl();
  const { uiState } = useContext(UIContext);

  const formatMessage = useCallback(
    (...args: Parameters<typeof format>) => {
      const descriptor = args[0];
      const message = format(...args);

      if (message === descriptor.id) {
        return '';
      }

      if (typeof message !== 'string') {
        return defaultMessage;
      }

      if (uiState.showFormatKeys) {
        return descriptor.id?.toString() || defaultMessage;
      }

      return message;
    },
    [format, uiState]
  );

  const formatRepeaterMessage = useCallback(
    (...args: Parameters<typeof format>) => {
      const id = args[0].id;
      const message = formatMessage(...args);
      if (message) {
        if (id && id.toString().includes('#repeater')) {
          return message.split('$$');
        }
        return [message];
      }
      return undefined;
    },
    [formatMessage]
  );

  const formatNumberMessage = useCallback(
    (...args: Parameters<typeof format>) => {
      const id = args[0].id;
      const message = formatMessage(...args);
      if (message) {
        if (id && id.toString().includes('#number')) {
          return parseFloat(message);
        }
        return parseFloat(message);
      }
      return undefined;
    },
    [formatMessage]
  );

  return {
    formatMessage,
    formatRepeaterMessage,
    formatNumberMessage,
    locale,
  };
};
