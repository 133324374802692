import { defaultShouldDehydrateQuery, QueryClient } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';

import { getBaseUrl } from './utils';
import { trpc } from './';

export const makeQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 30 * 1000,
      },
      dehydrate: {
        shouldDehydrateQuery: (query) => {
          return defaultShouldDehydrateQuery(query) || query.state.status === 'pending';
        },
      },
    },
  });
};

export const makeTrpClient = () => {
  return trpc.createClient({
    links: [httpBatchLink({ url: `${getBaseUrl()}/api/trpc` })],
  });
};
