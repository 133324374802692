import React, { Children, cloneElement, useContext, useMemo } from 'react';
import { Close } from '@hxm/assets/svg/menu/Close';
import { Logout } from '@hxm/assets/svg/menu/Logout';
import { UIContext } from '@hxm/contexts/ui';
import { NavItem } from '@hxm/types/navigation';
import { clientFetch } from '@hxm/utils/clientFetch';
import classnames from 'classnames/bind';
import { usePathname, useRouter } from 'next/navigation';

import { NavLink } from './NavLink';

import s from './Nav.module.scss';

const c = classnames.bind(s);

export type NavTranslations = {
  closeMenu: string;
  navigationLabel: string;
  logout: string;
  closeMainNavigation: string;
};

type IProps = {
  children: React.ReactNode;
  publicNav?: boolean;
  customLogout?: () => void;
  translations?: NavTranslations;
};

export const Nav = ({ children, publicNav, customLogout, translations }: IProps) => {
  const { uiState, setUIState } = useContext(UIContext);
  const router = useRouter();
  const pathname = usePathname();
  const handleClose = () => {
    setUIState({ isNavOpen: false });
  };

  const allTranslations = useMemo(() => {
    return {
      closeMenu: 'Loka valmynd',
      navigationLabel: 'Main Navigation',
      logout: 'Útskrá',
      closeMainNavigation: 'Close Main Navigation',
      ...(translations ?? {}),
    };
  }, [translations]);

  return (
    <nav
      className={c({ nav: true, open: uiState.isNavOpen })}
      aria-label={allTranslations.navigationLabel}
    >
      <div className={s.nav__inner}>
        <div className={s.nav__content}>
          <div className={s.nav__close}>
            <button
              type="button"
              onClick={handleClose}
              aria-label={allTranslations.closeMenu}
            >
              <Close />
            </button>
          </div>
          <ul className={s.nav__list}>
            {Children.toArray(children).map((ch, i) => {
              const child = ch as React.ReactElement;
              const children = (child.props as NavItem).childPages;
              return (
                <li className={s.nav__item} key={i}>
                  {cloneElement(child, {
                    tabIndex: 0,
                    className: s.nav__link,
                  })}
                  {children && (
                    <ul className={s.nav__subList}>
                      {children.map((child, j) => {
                        return (
                          <li className={s.nav__subItem} key={j}>
                            <NavLink
                              to={child.link}
                              className={s.nav__subLink}
                              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                              current={pathname?.includes(child.link)}
                            >
                              {child.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          {!publicNav ? (
            <button
              type="button"
              onClick={() => clientFetch('/api/auth/logout').then(() => router.push('/'))}
              className={s.nav__logout}
            >
              <Logout /> {allTranslations.logout}
            </button>
          ) : (
            customLogout && (
              <button
                type="button"
                onClick={() => customLogout()}
                className={s.nav__logout}
              >
                <Logout /> {allTranslations.logout}
              </button>
            )
          )}
        </div>
      </div>
      <button
        type="button"
        className={s.nav__backdrop}
        aria-label={allTranslations.closeMainNavigation}
        onClick={handleClose}
      />
    </nav>
  );
};
