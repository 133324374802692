'use client';

import type { ComponentPropsWithoutRef } from 'react';
import { usePrefersReducedMotion } from '@hxm/hooks/usePrefersReducedMotion';
import type { SiteLocale } from '@hxm/ui/i18n/i18n';
import { fixLink } from '@hxm/utils/fixLink';
import NextLink from 'next/link';

type NextLinkProps = ComponentPropsWithoutRef<typeof NextLink>;

export type LinkProps = Omit<NextLinkProps, 'href'> & {
  /**
   * Opt in or out of a page transition (i.e tabs might not require a transition).
   * Defaults to active page transitions
   */
  transition?: boolean;
  locale?: SiteLocale;
  to: NextLinkProps['href'];
};

export const Link = ({
  children,
  to: _to,
  transition = true,
  prefetch = false,
  target,
  scroll,
  rel,
  ...props
}: LinkProps) => {
  const to = typeof _to === 'string' ? fixLink(_to) : _to;
  const isExternal = typeof to === 'string' && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to);
  const reducedMotion = usePrefersReducedMotion();

  return (
    <NextLink
      href={to}
      scroll={scroll === undefined ? (reducedMotion ? true : transition) : scroll}
      prefetch={prefetch}
      rel={rel === undefined ? (isExternal ? 'noopener noreferrer' : undefined) : rel}
      target={target === undefined ? (isExternal ? '_blank' : undefined) : target}
      {...props}
    >
      {children}
    </NextLink>
  );
};
