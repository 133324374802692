/* eslint-disable @next/next/no-img-element */
import { useSettings } from '@hxm/hooks/useSettings';

import s from './Logo.module.scss';

export function LogoUrl({ url }: { url?: string | null }) {
  if (!url) {
    return null;
  }
  return <img src={url} alt="Logo" className={s.logo} />;
}

export const Logo = () => {
  const { settings } = useSettings();

  return <LogoUrl url={settings?.logo?.url} />;
};
