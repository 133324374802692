let preference: boolean | undefined;

/**
 * Check if the user prefers reduced motion. Fallbacks to `false`
 */
export function prefersReducedMotion(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }
  if (preference === undefined) {
    preference = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  }

  return preference;
}

/**
 * Check if the user prefers reduced motion. Fallbacks to `false`
 */
export function usePrefersReducedMotion() {
  return prefersReducedMotion();
}
